// import { Formio } from "formiojs";

const FieldComponent = Formio.Components.components.field;

class OneCallApplicantProgramComponent extends FieldComponent {
  static schema(...extend) {
    return FieldComponent.schema(
      {
        label: "One Call Applicant Program",
        key: "OneCallApplicantProgram",
        type: "OneCallApplicantProgram",
        apiendpoint: "",
        campusLable: "Campus",
        collegeLable: "College",
        preferenceLable: "Preference",
        activateCollege: false,
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: "One Call Applicant Program",
      icon: "th",
      group: "data",
      documentation: "/userguide/#textfield",
      weight: 0,
      schema: OneCallApplicantProgramComponent.schema(),
    };
  }

  constructor(component, options, data) {
    super(component, options, data);
    this.result = [];
    this.apiData = [];
    this.rowNumber = 0;
    this.allowToRemove = true;
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    this.fetchValidationValues = `${protocol}//${hostname}:${port}/api/method/medad_sis_adm.apis.v2.application.get_application_model_min_max_preferences_and_max_campuses?model={{_model}}`;
    this.maximumCampuses = 0;
    this.minimum = 0;
    this.maximum = 0;
  }

  async init() {
    super.init();
  }

  render(content) {
    return super
      .render(`<div class="form-group has-feedback formio-component formio-component-datagrid formio-component-data_grid">
        <div class="table-responsive">
            <table ref="applicantPrograms" id="applicantPrograms" class="table datagrid-table table-bordered">
                <thead class="">
                    <tr>
                        <th style="min-width:10px">#</th>
                        <th class="field-required" style="min-width:150px">${this.t(
                          this.component.campusLable
                        )}</th>
                        ${
                          this.component.activateCollege
                            ? `<th class="field-required" style="min-width:150px">${this.t(
                                this.component.collegeLable
                              )}</th>`
                            : ""
                        }
                        <th class="field-required" style="min-width:150px">${this.t(
                          this.component.preferenceLable
                        )}</th>
                        <th style="min-width:10px"></th>
                    </tr>
                </thead>
                <tbody>
                    ${
                      this.getValue() &&
                      this.getValue()["applicant_program"].length > 0
                        ? this.getValue()
                            ["applicant_program"].map(
                              (rowData, index) => `
                            <tr id="${index}" >
                                <td>${index + 1}</td>
                                <td>
                                    <select class="form-control" name="campus" id="campus${index}">
                                        <option value="${
                                          rowData.campus ? rowData.campus : ""
                                        }">${
                                rowData.campus
                                  ? rowData.campus
                                  : this.t("Choose a campus")
                              }</option>
                                        ${this.getCampus()
                                          .map((option) =>
                                            option === rowData.campus
                                              ? ""
                                              : `
                                                <option value="${option}">${option}</option>
                                            `
                                          )
                                          .join("")}
                                    </select>
                                </td>
                                ${
                                  this.component.activateCollege
                                    ? `
                                    <td>
                                        <select class="form-control" name="college" id="college${index}">
                                            <option value="${
                                              rowData.college
                                                ? rowData.college
                                                : ""
                                            }">${
                                        rowData.college
                                          ? rowData.college
                                          : this.t("Choose a college")
                                      }</option>
                                            ${this.getCollegesForCampus()
                                              .map((option) =>
                                                option === rowData.college
                                                  ? ""
                                                  : `
                                                    <option value="${option}">${option}</option>
                                                `
                                              )
                                              .join("")}
                                        </select>
                                    </td>`
                                    : ""
                                }
                                <td>
                                    <select class="form-control" name="preference" id="preference${index}">
                                        <option value="${
                                          rowData.preference
                                            ? rowData.preference
                                            : ""
                                        }">${
                                rowData.preference
                                  ? rowData.description
                                  : this.t("Choose a preference")
                              }</option>
                                        ${this.getPreferences(
                                          rowData.campus,
                                          this.component.activateCollege
                                            ? rowData.college
                                            : null
                                        )
                                          .map((option) =>
                                            option.name === rowData.preference
                                              ? ""
                                              : `
                                                <option value="${option.name}">${option.description}</option>
                                            `
                                          )
                                          .join("")}
                                    </select>
                                </td>
                                <td>
                                    <button class="btn btn-danger" id="deleteRowBtn" ><i class="fa fa-times-circle-o"></i></button>
                                </td>
                            </tr>
                        `
                            )
                            .join("")
                        : ""
                    }
                </tbody>


                <tfoot>
                    <tr>
                    <td colspan=${this.component.activateCollege ? "5" : "4"}>
                        <button tabindex="0" ref="addRowBtn" id="addRowBtn" class="btn btn-primary formio-button-add-row"><i class="fa fa-plus"></i> ${this.t(
                          "Add a preference"
                        )}</button>     
                    </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    `);
  }

  async attach(element) {
    if (this.getValue() && this.getValue()["applicant_program"].length > 0) {
      this.apiData = this.getValue()["applicant_program"];
      this.result = this.getValue()["applicant_program"];
    }

    await this.fetchDateFromAPI();

    this.loadRefs(element, {
      addRowBtn: "single",
      applicantPrograms: "single",
    });

    this.addEventListener(this.refs.addRowBtn, "click", () => {
      var campusesOption = [
        ...new Set(this.apiData.map((entry) => entry.campus)),
      ];

      var table = document
        .getElementById("applicantPrograms")
        .getElementsByTagName("tbody")[0];
      var newRow = table.insertRow(table.rows.length);

      var rowCell = newRow.insertCell(0);
      var campusCell = newRow.insertCell(1);
      var collegeCell = "";
      if (this.component.activateCollege) {
        collegeCell = newRow.insertCell(2);
      }
      var preferenceCell = newRow.insertCell(
        this.component.activateCollege ? 3 : 2
      );
      var actionCell = newRow.insertCell(
        this.component.activateCollege ? 4 : 3
      );

      var choiseNumber = this.rowNumber;
      this.rowNumber++;
      newRow.id = choiseNumber;

      rowCell.innerHTML = table.rows.length;

      campusCell.innerHTML =
        '<select id="campus' +
        choiseNumber +
        '" class="form-control" placeholder="Campus">' +
        '<option value="">' +
        this.t("Choose a campus") +
        "</option>" +
        campusesOption
          .map(
            (option) => '<option value="' + option + '">' + option + "</option>"
          )
          .join("") +
        "</select>";

      if (this.component.activateCollege) {
        collegeCell.innerHTML =
          '<select id="college' +
          choiseNumber +
          '" class="form-control" placeholder="College"><option value="">' +
          this.t("Choose a college") +
          "</option></select>";
      }
      preferenceCell.innerHTML =
        '<select id="preference' +
        choiseNumber +
        '" class="form-control" placeholder="Preference"><option value="">' +
        this.t("Choose a preference") +
        "</option></select>";
      actionCell.innerHTML =
        '<button class="btn btn-danger fa fa-times-circle-o" id="deleteRowBtn" ></button>';

      const campusSelect = document.getElementById("campus" + choiseNumber);
      if (this.component.activateCollege) {
        this.result.push({
          campus: "",
          college: "",
          preference: "",
          description: "",
        });
      } else {
        this.result.push({ campus: "", preference: "", description: "" });
      }
      this.addEventListener(
        campusSelect,
        "change",
        this.handleCampusSelection(choiseNumber)
      );
      this.setValue({ applicant_program: this.result });
    });

    this.addEventListener(document.body, "click", (event) => {
      const target = event.target;
      if (
        target.tagName === "BUTTON" &&
        target.classList.contains("btn-danger") &&
        this.allowToRemove
      ) {
        var table = document
          .getElementById("applicantPrograms")
          .getElementsByTagName("tbody")[0];
        var rowCount = table.rows.length;

        const row = target.closest("tr");
        const rowIndex = this.getRowIndex(event);
        this.result.splice(rowIndex, 1);
        row.remove();

        if (rowCount > 1) {
          for (var i = 0; i < rowCount - 1; i++) {
            table.rows[i].cells[0].innerText = i + 1;
          }
        }
        this.setValue({ applicant_program: this.result });
      }

      this.allowToRemove = !this.allowToRemove;
    });

    if (this.getValue() && this.getValue()["applicant_program"].length > 0) {
      var i = this.getValue()["applicant_program"].length - 1;
      while (i >= 0) {
        const campusSelect = document.getElementById("campus" + i);
        const collegeSelect = document.getElementById("college" + i);
        const preferenceSelect = document.getElementById("preference" + i);
        this.addEventListener(
          campusSelect,
          "change",
          this.handleCampusSelection(i)
        );
        this.addEventListener(
          collegeSelect,
          "change",
          this.handleCollegeSelection(i)
        );
        this.addEventListener(
          preferenceSelect,
          "change",
          this.handlePreferencesSelection()
        );
        i--;
      }
      this.rowNumber = this.getValue()["applicant_program"].length;
    }

    return super.attach(element);
  }

  getCampus() {
    const filteredCampuses = this.apiData.map((entry) => entry.campus);

    const uniqueCampuses = [...new Set(filteredCampuses)];

    return uniqueCampuses;
  }

  getCollegesForCampus(selectedCampus) {
    const filteredColleges = this.apiData
      .filter((entry) => entry.campus === selectedCampus)
      .map((entry) => entry.college);

    const uniqueColleges = [...new Set(filteredColleges)];

    return uniqueColleges;
  }

  getPreferences(selectedCampus, selectedCollege = null) {
    const filteredEntries = this.apiData.filter(
      (entry) =>
        entry.campus === selectedCampus &&
        (selectedCollege === null || entry.college === selectedCollege)
    );

    const namesAndPreferences = filteredEntries.map((entry) => ({
      name: entry.name,
      description: entry.description,
    }));

    return namesAndPreferences;
  }

  checkValidity(data, dirty, rowData) {
    //Call the super checkValidity method to perform the default input validation.
    const isValid = super.checkValidity(data, dirty, rowData);

    //Call the customValidation method to perform the custom validation.
    const customValidationResult = this.customValidation();

    //If the custom validation failed, set the error message and return false.
    if (customValidationResult !== true) {
      this.setCustomValidity(customValidationResult);
      return false;
    }

    //If the custom validation passed, return the result of the default input validation.
    return isValid;
  }

  customValidation() {
    const applicantPrograms = this.getValue();

    // Check if the array is not empty
    if (
      !applicantPrograms["applicant_program"] ||
      applicantPrograms["applicant_program"].length === 0
    ) {
      return this.t("Please provide at least one program preference.");
    }

    const seenPreferences = new Set();

    for (const program of applicantPrograms["applicant_program"]) {
      // Check if any field is empty
      if (
        !program.campus ||
        (!this.component.activateCollege && !program.preference) ||
        (this.component.activateCollege &&
          (!program.college || !program.preference))
      ) {
        return this.t("All fields in the preferences must be filled.");
      }

      // Check if the preference is not repeated
      const preferenceKey = `${program.campus}-${program.college}-${program.preference}`;
      if (seenPreferences.has(preferenceKey)) {
        return this.t("Duplicate preference found.");
      }

      // Check if the preference value is not empty
      if (program.preference.trim() === "") {
        return this.t("Preference value cannot be empty.");
      }

      seenPreferences.add(preferenceKey);
    }

    if(this.maximumCampuses > 0){
      const uniqueCampuses = [...new Set(applicantPrograms['applicant_program'].map(item => item.campus))];
      if(this.maximumCampuses < uniqueCampuses.length){
          return this.t(`The number of unique campuses exceeds the allowed limit`) +  `(${this.maximumCampuses}).`;
      }
    }

    if ((this.minimum > 0 && applicantPrograms['applicant_program'].length < this.minimum) || (this.maximum > 0 && applicantPrograms['applicant_program'].length > this.maximum)) {
        return this.t('The number of applicant programs is outside the allowed range') + `(${this.minimum > 0? this.minimum : 0} ` + this.t('to') + ` ${this.maximum > 0? this.maximum : this.t('no max')})` ;
    }

    // All validations passed
    return true;
  }

  async fetchDateFromAPI() {
    // try {
    //   var token = JSON.parse(localStorage.getItem("token"))["access_token"];
    // } catch (error) {
    //   var token = null;
    // }
    await fetch(this.processUrlString(this.component.apiendpoint), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // ...(token !== null && token !== undefined
        //   ? {
        //       Authorization: `Bearer ${
        //         JSON.parse(localStorage.getItem("token"))["access_token"]
        //       }`,
        //     }
        //   : {}),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const records = data["message"].map((record) => record);
        this.apiData = records;
      })
      .catch((error) => {
        console.error("Error:", error);
      });

      await fetch(this.processUrlString(this.fetchValidationValues), {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          // ...(token !== null && token !== undefined
          //   ? {
          //       Authorization: `Bearer ${
          //         JSON.parse(localStorage.getItem("token"))["access_token"]
          //       }`,
          //     }
          //   : {}),
        },
      })
      .then(response => response.json())
      .then(data => {
          const result = data['message'];
          this.maximumCampuses = result["maximum_number_of_campuses"];
          this.minimum = result["minimum"];
          this.maximum = result["maximum"];
      })
      .catch(error => {
          console.error('Error:', error);
      });
  }

  getRowIndex(event) {
    return event.target.closest("tr").querySelectorAll("td")[0].textContent - 1; // Adjusting for 0-based index
  }

  processUrlString(url) {
    // Regular expression to match {{ data.value }} and {{ data.special_person ? '=' : '!=' }}
    const regex = /\{\{([^{}]+)\}\}/g;

    // Replace placeholders with their corresponding values
    var processedUrl = url.replace(regex, (match, expression) => {
      // Evaluate the expression using the rootObject
      try {
        const value = eval(`this._data.${expression}`);
        return value;
      } catch (error) {
        console.error(`Error evaluating expression: ${expression}`);
        return match; // Keep the original placeholder if there is an error
      }
    });
    return processedUrl;
  }

  handleCampusSelection = (choiseNumber) => {
    return (event) => {
      const selectedCampus = event.target.value;
      const rowId = this.getRowIndex(event);
      const campusSelect = document.getElementById("campus" + choiseNumber);
      const selectedCampusValue = campusSelect.value;
      const preferenceSelect = document.getElementById(
        "preference" + choiseNumber
      );

      if (this.component.activateCollege) {
        const collegeSelect = document.getElementById("college" + choiseNumber);

        collegeSelect.innerHTML =
          '<option value="">' +
          this.t("Choose a college") +
          "</option>" +
          this.getCollegesForCampus(selectedCampus)
            .map(
              (option) =>
                '<option value="' + option + '">' + option + "</option>"
            )
            .join("");

        preferenceSelect.innerHTML =
          '<option value="">' + this.t("Choose a preference") + "</option>";
        this.addEventListener(
          collegeSelect,
          "change",
          this.handleCollegeSelection(choiseNumber)
        );
        this.result[rowId]["college"] = "";
      } else {
        preferenceSelect.innerHTML =
          '<option value="">' +
          this.t("Choose a preference") +
          "</option>" +
          this.getPreferences(selectedCampusValue)
            .map(
              (option) =>
                '<option value="' +
                option.name +
                '">' +
                option.description +
                "</option>"
            )
            .join("");

        this.addEventListener(
          preferenceSelect,
          "change",
          this.handlePreferencesSelection()
        );
      }

      this.result[rowId]["campus"] = selectedCampus;
      this.result[rowId]["preference"] = "";
      this.result[rowId]["description"] = "";
      this.setValue({ applicant_program: this.result });
    };
  };

  handleCollegeSelection = (choiseNumber) => {
    return (event) => {
      const selectedCollege = event.target.value;
      const preferenceSelect = document.getElementById(
        "preference" + choiseNumber
      );

      const campusSelect = document.getElementById("campus" + choiseNumber);
      const selectedCampusValue = campusSelect.value;

      preferenceSelect.innerHTML =
        '<option value="">' +
        this.t("Choose a preference") +
        "</option>" +
        this.getPreferences(selectedCampusValue, selectedCollege)
          .map(
            (option) =>
              '<option value="' +
              option.name +
              '">' +
              option.description +
              "</option>"
          )
          .join("");

      this.addEventListener(
        preferenceSelect,
        "change",
        this.handlePreferencesSelection()
      );
      const rowId = this.getRowIndex(event);
      this.result[rowId]["college"] = selectedCollege;
      this.result[rowId]["preference"] = "";
      this.result[rowId]["description"] = "";
      this.setValue({ applicant_program: this.result });
    };
  };

  handlePreferencesSelection = () => {
    return (event) => {
      const selectedPreference = event.target.value;
      const selectedDescription =
        event.target.options[event.target.selectedIndex].text;

      const rowId = this.getRowIndex(event);
      this.result[rowId]["preference"] = selectedPreference;
      this.result[rowId]["description"] = selectedDescription;

      this.setValue({ applicant_program: this.result });
    };
  };
}

OneCallApplicantProgramComponent.editForm = function () {
  return FieldComponent.editForm([
    {
      weight: 10,
      key: "api",
      label: "API Configuration",
      components: [
        {
          type: "input",
          input: true,
          key: "apiendpoint",
          label: "API Endpoint",
          placeholder: "Enter the API endpoint URL",
          description: "Specify the URL from which to retrieve data.",
          tooltip:
            "Enter the URL of the API endpoint that provides data for the component.",
          validate: {
            custom: function (value) {
              if (!value) {
                return "API Endpoint is required.";
              }

              return true;
            },
          },
          weight: 21,
        },
      ],
    },
    {
      weight: 10,
      key: "display",
      components: [
        {
          weight: 500,
          type: "checkbox",
          input: true,
          key: "activateCollege",
          label: "Allow College",
          tooltip:
            "Activate college to allow the applicant to select a college",
        },
      ],
    },
    {
      weight: 10,
      key: "display",
      components: [
        {
          weight: 500,
          type: "input",
          input: true,
          required: true,
          key: "campusLable",
          label: "Campus Lable",
          tooltip: "Text will display on table header",
        },
      ],
    },
    {
      weight: 10,
      key: "display",
      components: [
        {
          weight: 500,
          type: "input",
          input: true,
          required: true,
          key: "collegeLable",
          label: "College Lable",
          tooltip: "Text will display on table header",
        },
      ],
    },
    {
      weight: 10,
      key: "display",
      components: [
        {
          weight: 500,
          type: "input",
          input: true,
          required: true,
          key: "preferenceLable",
          label: "Preference Lable",
          tooltip: "Text will display on table header",
        },
      ],
    },
  ]);
};

Formio.use({
  components: {
    OneCallApplicantProgram: OneCallApplicantProgramComponent,
  },
});
